<template>
	<div class="exportsSetting">
		<div class="exportSets">接口配置</div>
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
			<!-- 在还没有设置的商户 -->
			<el-form-item label="APPID与密钥:" v-if="!AppId">
				<div class="brothClass" @click="bothPassworld(1)" style="width: 40px;">生成</div>
			</el-form-item>

			<!-- 设置之后 -->
			<el-form-item label="APPID:" v-if="AppId">
				<div>{{AppId}}</div>
			</el-form-item>
			<el-form-item label="密钥:" v-if="AppId">
				<div class="brothClass" @click="secondFun"  style="width: 40px;">重置</div>
				<div style="font-size: 14px;color: #9E9E9E;">出于安全考虑，接口密钥不会明文保存在系统上，若忘记请点击[重置]</div>

			</el-form-item>

			<el-form-item label="回调地址:" v-if="AppId">
				<el-input v-model="ruleForm.CallbackUrl"  clearable style="width:500px"></el-input>
			</el-form-item>
		</el-form>

		<!-- 生成密钥的弹框 -->
		<el-dialog :title="passwordTitle" :visible.sync="bothpasswordShow" width="700px" @closed="comeback">
			<div style="font-size: 14px;margin-bottom: 20px;" v-if="nameType==1">APPID：{{showAppId}}</div>
			<div>
				<span v-if="nameType==1">密钥：</span>
				<el-input v-model="inputValue" style="width:450px" disabled></el-input>
				<el-button v-clipboard:copy="inputValue" style="width:100px" v-clipboard:success="onCopy" v-clipboard:error="onError">
					复制
				</el-button>
			</div>
			<div style="font-size:14px;text-align: center;margin-top: 50px;">
				<el-checkbox v-model="checked">我已知晓确定后原密钥将失效，并且已复制保存好新密钥</el-checkbox>
			</div>
			<div style="text-align:center;margin-top: 25px;">
				<el-button size="small" :disabled="!checked" :type="checked?'primary':'info'" @click="passwordSave" style="width:150px"
				 v-loading="saveLoading">确定并关闭</el-button>
			</div>
		</el-dialog>
		
		<!-- 重置的二次弹框 -->
		<el-dialog title="" :visible.sync="secondVisvble" width="350px">
			<div style="line-height: 25px;">密钥确认重置后，原密钥将失效，是否确认继续重置？</div>
			<div style="text-align:center;margin-top: 20px;">
				<el-button size="small" style="width:90px" @click="secondVisvble=false">关闭</el-button>
				<el-button size="small"  style="width:90px;margin-left: 15px;" type="primary"  @click="bothPassworld(2)">继续重置</el-button>
			</div>
		</el-dialog>

		<!-- 底部保存 -->
		<div class="bottom-save-btn">
			<el-button style="width:240px;" @click="saveBtns" type="primary" :loading="loading">保存</el-button>
		</div>

	</div>
</template>

<script>
	import {
		mallerponfig,
		mallerponfigGenerate,
		mallerponfigtartuse,
		mallerponfigReset,
		mallerponfigallbackurl
	} from '@/api/goods'
	export default {
		data() {
			return {
				ruleForm: {
					CallbackUrl: ''
				},
				rules: {

				},
				bothpasswordShow: false,
				inputValue: '这是一个链接',
				checked: false,
				passwordTitle: '生成APPID与密钥',
				loading: false,
				AppId: '',
				nameType: '',
				saveLoading: false,
				showAppId: '',
				secondVisvble:false,
			}
		},
		created() {
			this.getInfor()
		},
		methods: {
			// 接口配置初始化
			async getInfor() {
				try {
					let result = await mallerponfig()
					if (result.IsSuccess) {
						this.AppId = result.Result.AppId
						this.ruleForm.CallbackUrl = result.Result.CallbackUrl
					}

				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {

				}
			},
			// 重置
			secondFun(){
				this.secondVisvble=true
			},
				
			comeback() {
				this.checked = false
			},
			// 生成密钥
			async bothPassworld(type) {
				try {
					this.nameType = type
					let result = {}
					if (type == 1) {
						this.passwordTitle = '生成APPID与密钥'
						result = await mallerponfigGenerate()

					} else {
						this.passwordTitle = '重置密钥'
						this.secondVisvble=false
						result = await mallerponfigReset()
					}
					if (result.IsSuccess) {
						this.showAppId = result.Result.AppId
						this.inputValue = result.Result.AppSecret
					}


				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.bothpasswordShow = true;
				}


			},
			async passwordSave() {
				try {
					this.saveLoading = true
					if (this.nameType == 1) {
						let result1 = await mallerponfigtartuse()
						// if (result1.IsSuccess) {
						// 	this.$message({
						// 		showClose: true,
						// 		type: 'success',
						// 		message: '操作成功'
						// 	});
						// }
					}

				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.getInfor()
					this.bothpasswordShow = false
					this.saveLoading = false
				}

			},
			// 保存
			async saveBtns() {
				try {
					this.loading = true
					let data = {
						CallbackUrl: this.ruleForm.CallbackUrl
					}
					let results = await mallerponfigallbackurl(data)
					if (results.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '保存成功'
						});
					}

				} catch (e) {
					//TODO handle the exception
				} finally {
					this.getInfor()
					this.loading = false
				}

			},

			onCopy() {
				this.$message({
					message: "复制成功！",
					type: 'success'
				});

			},
			onError() {
				this.$message({
					message: "复制失败！",
					type: 'error'
				});
			},

		}
	}
</script>

<style lang="less" scoped>
	.exportsSetting {
		padding: 10px 10px 30px;
		background: #fff;

		.exportSets {
			font-size: 14px;
			padding: 10px;
			border-bottom: 1px solid #eee;
			margin-bottom: 15px;
		}

		.brothClass {
			color: #409EFF;
			font-size: 14px;
			cursor: pointer;
		}

		::v-deep .el-dialog__body {
			// padding: 0 20px 35px !important
		}

		.bottom-save-btn {
			position: fixed;
			width: 100%;
			background: #fff;
			bottom: 0;
			text-align: center;
			padding: 20px 0;
			z-index: 100;
			box-shadow: 13px 1px 6px #999;
		}
	}
</style>
